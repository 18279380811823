<template>
  <div class="final-page">
    <GameHeader />
    <DialogPhone
      v-if="sentingPhone"
      @finished="handleWantOffer('phoneSent')"
      @close="sentingPhone = false"
      class="w-full h-full"
      style="position: fixed"
    />
    <!-- <GameHeader /> -->
    <!-- <router-link to="/top" class="top-btn">Турнирная таблица</router-link> -->
    <div class="wire wire--1">
      <img
        src="/images/LandingPageData/wire-1.png"
        alt="wire"
        class="wire__pic"
      />
    </div>
    <div class="shine shine--1">
      <img src="/images/results/results-shine-1.png" alt="shine" class="pic" />
    </div>
    <div class="final-page-block">
      <div class="final-page-block-content">
        <h1 class="title" :class="{ 'title--small': this.incorrects > 7 }">
          {{ title }}
        </h1>
        <div class="final-page-block-subtitle">
          <div v-if="this.incorrects <= 7" class="salary">
            <p>стоишь не меньше</p>
            <h1>{{ professional.salary }} ₽</h1>
          </div>
          <div class="flex flex-col" style="margin-top: 20px">
            <p class="answer">
              Правильных ответов
              <span class="answer-text">{{ corrects + `/15` }}</span>
            </p>
            <p class="answer">
              Пройдено боссов
              <span class="answer-text">{{ wins }}/3</span>
            </p>
          </div>
          <div v-if="!user.data.want_offer && this.incorrects <= 7" class="offer mt-20">
            <p class="offer-text">
              Но нужно с тобой познакомиться поближе. Скорей откликайся и
              получай свой оффер
              {{ user.want_offer }}
            </p>
            <div class="buttons mt-8">
              <button
                class="geecko-button mr-5"
                @click="handleWantOffer('yes')"
              >
                Да, хочу
              </button>
              <button
                class="geecko-button--outline"
                @click="handleWantOffer('no')"
              >
                Нет
              </button>
            </div>
          </div>
          <div v-if="user.data.want_offer === 'positive'" class="offer mt-20">
            <p class="offer-text">
              Спасибо за твой ответ!<br />Будем рады видеть тебя в нащей команде
              :)
            </p>
          </div>
          <div v-if="user.data.want_offer === 'negative'" class="offer mt-20">
            <p class="offer-text">
              Спасибо за твой ответ! Будем рады узнать, если ты передумаешь<br /><a
                @click="handleWantOffer('yes')"
                class="cursor-pointer red"
                >Я передумал и хочу работать в Сбере</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="final-page-container final-page-container__result" style="margin-bottom: 50px;">
      <result-block :stats="stats" />
    </div> -->
    <!-- <div
      class="final-page-container"
      v-if="top && top.current && top.current.rank"
    >
      <h2>
        <span style="color: #ffef40">{{ top.current.rank }} место</span> в
        турнире
      </h2>
    </div> -->
    <!-- <div class="final-page-container" v-if="currentConf">
      <top-table
        :currentUsers="currentUsers"
        :currentUser="currentUser"
        :currentDate="currentDate"
        showCurrentUser
      />
    </div> -->
    <!-- <div class="final-page-container">
      <prize actionText="Пройти задание" />
    </div> -->
    <div class="final-page-container">
      <social-share-block
        v-if="this.incorrects <= 7"
        :salary="professional.salary"
      />
    </div>
    <div class="final-page-container">
      <Feedback />
    </div>
    <!-- <div class="final-page-container">
      <div
        style="
          margin-top: 40px;
          width: 100%;
          max-width: 600px;
          padding-left: 25px;
          background: #131120;
          padding: 0px 25px;
          padding-top: 25px;
        "
      >
        <Jobs />
      </div>
    </div> -->
    <div class="final-page-container">
      <div class="work">
        <div class="work__row">
          <div class="work__col">
            <img
              src="/images/sberbank-logo.svg"
              alt="sber"
              class="work__logo"
            />
          </div>
          <div class="work__col">
            <div class="work__title">Работа в Сбере</div>
            <p class="work__descr">
              Сбер находится в постоянном поиске талантливых специалистов из
              разных областей. Посмотри кого мы ищем сейчас
            </p>
            <a href="https://rabota.sber.ru/" target="_blank" class="work__link"
              >Смотреть вакансии</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="final-page-container">
      <div
        style="
          margin-top: 40px;
          width: 100%;
          max-width: 600px;
          padding-left: 25px;
        "
      >
        <button class="geecko-button" @click="restart()">Новая игра</button>
      </div>
    </div>
    <div class="wire wire--2">
      <img
        src="/images/LandingPageData/wire-2.png"
        alt="wire"
        class="wire__pic"
      />
    </div>
    <div class="shine shine--2">
      <img src="/images/results/results-shine-2.png" alt="shine" class="pic" />
    </div>
  </div>
</template>
<script>
// import ResultBlock from "@/components/Result/ResultBlock";
import Feedback from '@/components/Result/Feedback';
// import Prize from "@/components/Result/Prize";
// import GameHeader from "@/components/Game/GameHeader.vue";
// import TopTable from "@/components/UI/TopTable";
import SocialShareBlock from '@/components/Result/SocialShareBlock';
// import Jobs from "@/components/Result/Jobs";
import DialogPhone from '@/components/Game/DialogPhone.vue';
import GameHeader from '../components/Game/GameHeader.vue';

export default {
  name: 'FinalPage',
  components: {
    // ResultBlock,
    Feedback,
    DialogPhone,
    // Prize,
    // GameHeader,
    // TopTable,
    SocialShareBlock,
    // Jobs,
    GameHeader,
  },
  data() {
    return {
      salary: null,
      offer: true,
      sentingPhone: null,
      rating: 0,
      time: null,
      answears: 18,
      goodAnswears: 15,
      badAnswears: 3,
      asnweared: false,
      feedbackSend: false,
      stats: null,
      top: null,
      confs: [],
      currentConf: null,
      users: [],
      currentUser: null,
      dates: [],
      currentDate: null,
      winners: {},
      currentUsers: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    barWidth() {
      const w = (((100 / this.answears) * this.goodAnswears) / 100) * 600;
      return w;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[
        this.gameInfo.current_level
      ];
    },

    corrects() {
      return (
        this.gameInfo.result.level.c_1.correct +
        this.gameInfo.result.level.c_2.correct +
        this.gameInfo.result.level.c_3.correct
      );
    },
    wins() {
      let s;
      if (this.corrects < 3) s = 0;
      if (this.corrects >= 3 && this.corrects < 6) s = 1;
      if (this.corrects >= 6 && this.corrects < 9) s = 2;
      if (this.corrects === 9) s = 3;
      return s;
    },
    incorrects() {
      return (
        this.gameInfo.result.level.c_1.incorrect +
        this.gameInfo.result.level.c_2.incorrect +
        this.gameInfo.result.level.c_3.incorrect
      );
    },
    professional() {
      if (this.incorrects > 7) {
        return { prof: '«Начинающий»', salary: '50000' };
      }
      if (this.incorrects >= 5 && this.incorrects <= 7) {
        return { prof: '«Junior»', salary: '200000' };
      }
      if (this.incorrects >= 3 && this.incorrects <= 4) {
        return { prof: '«Middle»', salary: '300000' };
      }
      if (this.incorrects <= 2) {
        return { prof: '«Senior»', salary: '400000' };
      }
      return '';
    },
    title() {
      if (this.incorrects > 7) {
        return 'Сегодня не повезло, но ты всегда можешь попробовать себя в других сферах Сбера';
      } else {
        return `Вероятно ты ${this.professional.prof}`;
      }
    },
  },
  async created() {
    this.$log('created');
    // const { data } = await this.$http("/api/game/get-result");
    // this.$log("data", data);
    // this.stats = data;
    // this.$http.get("/conf").then((resp) => {
    //   this.currentConf = resp.data?.id;
    //   if (this.currentConf) {
    //     this.$http.get(`/conf/${this.currentConf}/top`).then((resp) => {
    //       this.users = resp.data.users;
    //       this.currentUser = resp.data.current;
    //       this.dates = resp.data.conf.dates.map((d) =>
    //         new Date(d).toLocaleString("ru", { month: "long", day: "numeric" })
    //       );
    //       this.winners = Object.fromEntries(
    //         Object.entries(resp.data.winners).map((x) => [
    //           new Date(x[0].replace(/-/g, "/")).toLocaleString("ru", {
    //             month: "long",
    //             day: "numeric",
    //           }),
    //           x[1],
    //         ])
    //       );
    //       const currentDate = new Date().toLocaleString("ru", {
    //         month: "long",
    //         day: "numeric",
    //       });
    //       if (this.winners[currentDate]) {
    //         // выводим победителей за текущий день
    //         this.currentUsers = this.winners[currentDate];
    //       } else if (this.dates.includes(currentDate)) {
    //         // если за текущий день нет победителей, выводим топ users
    //         this.currentUsers = this.users.slice(0, 5);
    //       } else if (this.dates.length) {
    //         // если конференция закончилась, выводим победителей последнего дня
    //         this.currentUsers = this.winners[this.dates[this.dates.length - 1]];
    //       }
    //     });
    //   }
    // });
  },
  async mounted() {
    this.$log('mounted');
  },
  methods: {
    async handleWantOffer(answer) {
      if (answer === 'phoneSent') {
        this.sentingPhone = false;
        this.$http.post('/api/intentions', {
          state: 'positive',
        });
      }
      if (answer === 'yes') {
        if (!this.user.data.phone) return (this.sentingPhone = true);
        else {
          this.$http.post('/api/intentions', {
            state: 'positive',
          });
        }
      }
      if (answer === 'no') {
        this.offer = false;
        this.$http.post('/api/intentions', {
          state: 'negative',
        });
      }
      await this.$store.dispatch('user/fetchUser');
    },
    async restart() {
      this.$log('handleRestart');
      await this.$store.dispatch('game/finishGame');
      await this.$store.dispatch('game/restartGame');
      this.$router.replace('/game').catch(() => {});
      // location.reload()
    },
    sberLink() {},
    share(platform) {
      let url = null;
      switch (platform) {
        case 'vk':
          url = 'http://vkontakte.ru/share.php?url=';
          break;
        case 'tw':
          url = 'http://twitter.com/share?url=';
          break;
        case 'fb':
          url = 'http://www.facebook.com/sharer.php?u=';
          break;
        default:
          break;
      }
      window.open(url + window.location.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  @apply font-sbsans-display font-bold;
  font-size: 48px;
  margin-bottom: 32px;
  text-transform: none;
  max-width: none !important;

  &--small {
    font-size: 36px;
    line-height: 42px;
    max-width: 700px !important;
  }
}
.answer {
  @apply font-blenderpro-bold uppercase flex items-center justify-center;
  color: rgba(white, 0.5);
  &-text {
    color: white;
    margin-bottom: 2px;
    margin-left: 10px;
  }
}
.city {
  background-size: 1500px;
  background-position: bottom;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  max-height: 520px;
}
.geecko-button,
.geecko-button--outline {
  @apply font-sbsans-ui font-semibold;
  font-size: 17px;
  line-height: normal;
  height: auto;
  padding: 14px 40px;
  text-transform: none;
}
.offer {
  &-text {
    @apply font-sbsans-ui;
    font-size: 18px;
    line-height: 25px;
    color: white;
  }
  a {
    color: yellow;
    text-decoration: underline;
  }
}
.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 696px;
  background-image: linear-gradient(
    180deg,
    rgba(35, 1, 42, 0) 0%,
    #090812 100%
  );
  z-index: 10;
}
.final-page {
  position: relative;
  overflow: hidden;
  padding-bottom: 64px;
  & .top-btn {
    z-index: 9999;
    position: absolute;
    top: 8px;
    right: calc(50% - 90px);
  }
  color: #fff;
  background: black;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid red;
  position: relative;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  & .top-table {
    max-width: 600px;
    width: 100%;
  }
  &-block {
    // background: url("/images/sky-first.png");
    background-size: 100%;
    // border: 1px solid red;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100vw;
    width: 100vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 196px 16px 83px 16px;
    &-content {
      margin-top: -50px;
      // border: 1px solid red;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      max-width: 1280px;
      z-index: 99;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    &-buttons-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    &-clouds {
      background-image: url('/images/clouds.png');
      height: 320px;
      width: 100%;
      background-size: 5000px;
      position: absolute;
      top: 0px;
      left: 0;
      background-position-y: -160px;
      background-repeat: repeat;
      // z-index: 1000;
      animation: animCloud 30s infinite linear;
    }
    &-city-bg {
      position: absolute;
      background-image: url('/images/city-first-bg.png');
      background-size: 1400px;
      background-position: bottom -153px center;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 1;
    }
    &-city {
      position: absolute;
      background-image: url('/images/city-first.png');
      background-size: 1280px;
      background-position: bottom -187px center;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0px;
      z-index: 3;
    }
    &-blur-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(35, 1, 42, 0) 0%,
        #090812 90%
      );
      z-index: 10;
    }

    &-subtitle {
      font-family: 'BlenderPro Thin';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #f8fafc;
      width: 100%;
      display: flex;
      @apply flex-col items-center;
      justify-content: center;
      & p {
        width: 100%;
        max-width: 550px;
      }
      .salary {
        color: #67ff76;
        border: 1px solid #67ff76;
        padding: 15px;
        padding-bottom: 0;
        border-radius: 20px;
        @apply flex flex-col items-start justify-center;
        p {
          @apply font-sbsans-display;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: #67ff76;
          /* or 23px */
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
        h1 {
          @apply font-sbsans-display;
          font-weight: bold;
          font-size: 48px;
          line-height: normal;
          margin: 0;
          padding: 0;
          text-align: center;
          color: #67ff76;
        }
      }
    }
    & h1 {
      width: 100%;
      text-align: center;
    }
  }
  &-container {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 1200px;
    width: 100%;
    z-index: 1000;
    & h2 {
      font-size: 32px;
      line-height: 45px;
    }
    &__result {
      margin-top: 150px;
    }
  }
}
@keyframes animCloud {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1745px;
  }
}
.sber {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  // height: 158px;
  background-color: #131120;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &-body {
    display: flex;
    flex-wrap: row;
    padding: 25px;
    width: 100%;
  }
  &-text-container {
    margin-left: 30px;
    max-width: 400px;
    width: 100%;
    & h5 {
      width: 100%;
    }
    & p {
      padding: 0;
      margin: 0;
      color: #858a9c;
    }
  }
}
p {
  color: #e0e0e0;
}
h5 {
  color: #e0e0e0;
}
@media screen and (max-width: 768px) {
  .geecko-textarea {
    max-width: 320px;
    min-width: 0;
    width: 100%;
  }
  .final-page {
    padding-bottom: 100px;
    &-container {
      &__result {
        margin-top: 40px;
      }
    }
    &-block {
      padding: 100px 16px 63px 16px;
      background-size: cover;
      & h1 {
        font-size: 26px;
        line-height: 36px;
      }
      &-subtitle {
        & p {
          font-size: 19px;
          line-height: 19px;
        }
        .salary {
          color: #67ff76;
          border: 1px solid #67ff76;
          padding: 10px 10px 0px 10px;
          border-radius: 10px;
          margin-top: 50px;
          @apply flex flex-col items-start justify-center;
          p {
            font-family: 'Sans Display';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            margin-bottom: 10px;
            line-height: 130%;
            color: #67ff76;
            /* or 23px */
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
          h1 {
            font-family: 'Sans Display';
            font-weight: bold;
            font-size: 32px;
            line-height: 130%;
            margin: 0;
            padding: 0;
            /* or 62px */
            text-align: center;
            color: #67ff76;
          }
        }
      }
      &-buttons-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
      &-city {
        background-size: cover;
        background-position: bottom -40px center;
        background-repeat: no-repeat;
      }
      &-city-bg {
        background-size: contain;
        background-position: bottom 0px center;
        background-repeat: no-repeat;
      }
      &-clouds {
        display: none;
        /* background-image: url("/images/clouds.png");
        height: 320px;
        width: 100%;
        background-size: 7000px;
        position: absolute;
        top: -130px;
        left: 0;
        background-position-y: -60px;
        background-repeat: repeat;
        z-index: 2;
        animation: animCloud 30s infinite linear; */
      }
    }
  }
}
.work {
  max-width: 600px;
  margin-top: 40px;
  padding: 30px 24px;
  background-color: #131120;

  &__row {
    @apply flex;
  }

  &__col {
    &:not(:last-child) {
      flex-shrink: 0;
      max-width: 85px;
      margin-right: 30px;
    }
  }

  &__title {
    @apply font-blenderpro-bold uppercase;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 4%;
    margin-bottom: 14px;
  }

  &__descr {
    @apply font-blenderpro-regular;
    font-size: 20px;
    line-height: 28px;
    color: #e0e0e0;
  }

  &__link {
    @apply font-blenderpro-regular transition-colors ease-in-out;
    font-size: 12px;
    display: inline-block;
    padding: 4px 8px;
    margin-top: 20px;
    border: 1px solid #e3e6e8;
    border-radius: 4px;
    color: #e3e6e8;

    &:hover {
      background-color: #e3e6e8;
      color: black;
    }

    &:focus {
      background-color: #e3e6e8;
      color: black;
    }

    &:active {
      opacity: 0.7;
    }
  }
}
.wire {
  @apply absolute;

  &--1 {
    display: none;
    transform: scale(-1, 1);

    @screen tn {
      top: 0px;
      left: -355px;
      display: block;
    }

    @screen sm {
      top: 0px;
      left: -295px;
    }

    @screen lg {
      left: auto;
      right: -40%;
      transform: scale(1);
    }

    @screen xxl {
      right: -21%;
    }

    @screen xxxl {
      right: -72px;
    }

    .wire__pic {
      width: 672px;
    }
  }

  &--2 {
    display: none;

    @screen sm {
      bottom: 0px;
      left: -327px;
      display: block;
      transform: rotate(-45deg);
    }

    .wire__pic {
      width: 672px;
    }
  }
}
.shine {
  @apply absolute;

  &--1 {
    top: 0;
    right: 0;
  }

  &--2 {
    left: 0;
    bottom: 0;
  }
}
</style>
