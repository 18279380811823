<template>
  <div class="banner">
    <div class="banner-body-block">
      <img
        src="/images/results/results-message.png"
        alt="message"
        class="banner-message"
      />
      <div class="banner-body-text-container">
        <p class="banner-text">Как IT Архитектор я стою не меньше</p>
        <h5>{{ salary }} <span style="margin-left: -26px">₽</span></h5>
        <div class="flex flex-col" style="margin-top: 30px">
          <p class="banner-answer">
            <span class="banner-answer-text">Правильных ответов</span>
            <span class="text-white" style="margin-bottom: 2px">{{
              corrects + `/15`
            }}</span>
          </p>
          <p class="banner-answer">
            <span class="banner-answer-text">Побед над боссами</span>
            <span class="text-white" style="margin-bottom: 2px"
              >{{ wins }}/3</span
            >
          </p>
        </div>
        <IconSberLogo class="sber-logo" />
      </div>
    </div>
    <div class="banner-body">
      <div class="links-block">
        <button class="social-share-item vkontakte" @click="share('vk')" />
        <button class="social-share-item facebook" @click="share('fb')" />
        <button class="social-share-item twitter" @click="share('tw')" />
      </div>
    </div>
  </div>
</template>
<script>
import IconSberLogo from '@/components/Icons/IconSberLogo';
export default {
  name: 'SocialShareBlock',
  props: {
    salary: { type: String, default: 'lol' },
  },
  components: {
    IconSberLogo,
  },
  data() {
    return {};
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    corrects() {
      return (
        this.gameInfo.result.level.c_1.correct +
        this.gameInfo.result.level.c_2.correct +
        this.gameInfo.result.level.c_3.correct
      );
    },
    wins() {
      let s;
      if (this.corrects < 3) s = 0;
      if (this.corrects >= 3 && this.corrects < 6) s = 1;
      if (this.corrects >= 6 && this.corrects < 9) s = 2;
      if (this.corrects === 9) s = 3;
      return s;
    },
  },
  methods: {
    share(platform) {
      let url = null;
      switch (platform) {
        case 'vk':
          url = 'http://vkontakte.ru/share.php?url=';
          break;
        case 'tw':
          url = 'http://twitter.com/share?url=';
          break;
        case 'fb':
          url = 'http://www.facebook.com/sharer.php?u=';
          break;
        default:
          break;
      }
      window.open(url + window.location.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.sber-logo {
  width: 85px;
  margin: 10px 0px;
  @apply absolute;
  left: 30px;
  bottom: 10px;
}
.banner {
  max-width: 600px;
  width: 100%;
  background-color: #131120;
  position: relative;
  &-message {
    @apply absolute;
    right: 65px;
    top: 107px;
    display: none;
    max-width: 200px;

    @media screen and (min-width: 510px) {
      display: block;
    }
  }
  &-text {
    @apply font-sbsans-display font-semibold;
    font-size: 16px;
    margin-bottom: 8px;
    height: auto !important;
  }
  &-answer {
    @apply font-blenderpro-bold flex items-center;
    font-size: 14px;
    text-transform: uppercase;
    height: auto !important;

    &-text {
      color: rgba(white, 0.5);
      margin-right: 10px;
    }
  }
  &-body {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    & h5 {
      font-size: 18px;
      line-height: 24px;
      color: #e0e0e0;
    }
    &-block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      background-image: url('/images/results/results-character.png'),
        url('/images/results/results-shine.png');
      background-position: right;
      background-repeat: no-repeat;
      max-width: 600px;
      // border: 1px solid red;
      width: 100%;
      position: relative;
      height: 315px;
    }

    &-text-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 30px;
      width: 100%;
      max-width: 400px;
      // height: 100%;
      // border: 1px solid red;
      :nth-child(3n) {
        margin-top: 20px;
      }
      & h5 {
        @apply font-sbsans-text-mono font-bold;
        font-size: 54px;
        line-height: 1;
        margin: 0;
        padding: 0;
        /* or 62px */
        width: 100%;
        text-align: left;
        color: #67ff76;
        margin-top: 0px;
        background-image: url('/images/LandingPageData/title-pattern.png');
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-font-smoothing: antialiased;
      }
      & p {
        color: #e0e0e0;
        width: 100%;
        height: 40px;
        font-size: 18px;
      }
    }
  }
}

.links-block {
  display: flex;
  flex-wrap: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
}
.social-share-item {
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}
.vkontakte {
  background-image: url('/images/VK.png');
}
.facebook {
  background-image: url('/images/FB.png');
}
.twitter {
  background-image: url('/images/TW.png');
}
@media screen and (max-width: 768px) {
  .sber-logo {
    left: 10px;
  }
  .banner {
    &-body {
      // padding: 0px;
      &-block {
        height: 300px;
      }
      & h5 {
        font-size: 16px;
        line-height: 18px;
      }
      &-text-container {
        padding: 20px;
        & h5 {
          font-size: 36px;
          line-height: 120%;
          margin-top: 0px;
        }
        & p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
