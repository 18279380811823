<template>
  <div class="review">
    <div v-if="feedbackSend" class="review-body">
      <h2>Спасибо за отзыв!</h2>
    </div>
    <div v-else class="review-body">
      <h2 class="title">Тебе понравился квиз?</h2>
      <form @submit.prevent="handleSubmit()">
        <!-- rating -->
        <star-rating
          v-model="rating"
          :star-size="24"
          :show-rating="false"
          :read-only="feedbackSend"
        />
        <!-- comment textarea -->
        <textarea
          v-model="comment"
          type="text"
          placeholder="Отзыв или комментарий. Эти сообщения прочитают разработчики игры"
          class="geecko-textarea"
        />
        <!-- send feedback button -->
        <div
          class="flex flex-row flex-wrap w-full"
          :style="{
            paddingTop: '10px',
          }"
        >
          <button
            class="button"
            type="submit"
            style="margin-top: 20px"
            :disabled="rate"
          >
            Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  components: { StarRating },
  props: {},
  data() {
    return {
      rating: 0,
      comment: '',
      submitting: false,
      feedbackSend: false,
    };
  },
  computed: {
    rate() {
      if (this.rating) {
        return false;
      }
      return true;
    },
  },
  watch: {
    rating: {
      deep: true,
      handler () {
        this.saveRate()
        // console.log('rate sent', to)
      }
    }
  },
  mounted() {},
  methods: {
    saveRate() {
      this.$http.post('/api/game/feedback', {
        rating: this.rating,
        comment: this.comment,
      })
    },
    handleSubmit() {
      this.$log('handleSubmit');
      this.submitting = true;
      this.$http
        .post('/api/game/feedback', {
          rating: this.rating,
          comment: this.comment,
        })
        .then(() => {
          // this.$toaster.success('success');
          this.feedbackSend = true;
          this.comment = '';
          this.rating = 0;
        })
        .catch(() => {
          // this.$toaster.error('failure');
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply font-blenderpro-bold uppercase;
  font-size: 24px;
  margin-bottom: 16px;
}
.button {
  @apply font-blenderpro-bold uppercase transition-colors ease-in-out;
  font-size: 14px;
  padding: 10px 30px;
  background: linear-gradient(-135deg, transparent 10px, #ffd234 0),
    linear-gradient(45deg, transparent 15px, #141221 0);
  color: black;

  &:hover {
    background: linear-gradient(-135deg, transparent 10px, #ffa723 0),
      linear-gradient(45deg, transparent 15px, #141221 0);
  }

  &:disabled {
    background-color: #7f7e8a;
    background: linear-gradient(-135deg, transparent 10px, #7f7e8a 0),
      linear-gradient(45deg, transparent 15px, #141221 0);
    color: #131120;
  }
}
.review {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  background-color: #131120;
  position: relative;
  &-body {
    padding: 25px;
  }
}
.geecko-textarea {
  @apply font-blenderpro-regular;
  margin-top: 15px;
  padding: 10px;
  background: #2e2c3f;
  outline: 1px;
  outline-color: transparent;
  border-radius: 0;
  color: #aeadb1;
  width: 100%;
  max-height: 80px;
  min-height: 90px;
  max-width: 520px;
  // min-width: 520px;
  width: 100%;
  font-size: 12px;

  &::placeholder {
    color: rgba(#e0e0e0, 0.7);
  }
}
@media screen and (max-width: 768px) {
  .review {
    &-body {
      & h2 {
        font-size: 26px;
        line-height: 36px;
      }
    }
  }
}
</style>
